<template>
  <v-main>
    <v-header
      :headerImage="image"
      :displayOverlay="true"
      :overlayCopy="overlayCopy"
    />
    <container type="bg-dark" id="start">
      <title-and-description
        titleSize="h2"
        title="Public Relations"
      >
        <p>
          With a passion for getting your business top-of-mind within media
          circles, our team of Media Relations maestros are rightly acknowledged
          as some of the most highly effective specialists. They are experts in
          uncovering the right stories and knowing exactly how to communicate
          them to relevant journalists and media outlets. Q Communications
          understands that PR is evolving and needs to deliver beyond the
          traditional outlets; we engage multimedia tools to ensure your story
          hits multiple platforms, increases your digital presence and drives
          traffic to your website.
        </p>
      </title-and-description>
      <hr />
      <services-list :serviceList="prServices" />
    </container>
    <container type="bg-light-image background-cover">
      <v-row>
        <v-col><h1 class="text-center">Some of Our Projects</h1></v-col>
      </v-row>
      <project-grid :filter="['PR']" />
      <center>
        <v-btn tile large color="primary" class="my-10" to="/work"
          >View all of our work</v-btn
        >
      </center>
    </container>
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import ProjectGrid from "../components/ProjectGrid.vue";
import ServicesList from "../components/blocks/ServicesList.vue";

export default {
  name: "PR",
  components: {
    vHeader,
    ProjectGrid,
    ServicesList,
  },
  data() {
    return {
      image: require("@/assets/backgrounds/pr_header.jpg"),
      overlayCopy: [
        {
          copy: "It takes years to build",
          size: "40",
        },
        {
          copy: "a great reputation,",
          size: "40",
        },
        {
          copy: "give minutes to destroy it",
          size: "40",
        },
        {
          copy: "- Warren Buffet",
          size: "25",
        },
      ],
      prServices: [
        "Communications Retainer and Project Services",
        "Amplification of International Brands to Relevant Audiences",
        "Strategic Communications Strategies - Development and Implementation",
        "Campaign Concepts",
        "Press Kit Creation",
        "Key Message Creation",
        "Press Office",
        "Media Relations",
        "Spokesperson Profiling",
        "Content Creation and Copywriting",
        "Media Monitoring and Measurement",
        "PR Support for Product Launches",
        "Press Conference and Media Event Management",
        "Crisis Communications and Reputation Management",
        "Corporate Counsel",
        "Sports Talent Management"
      ],
    };
  },
};
</script>
